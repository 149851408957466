::-webkit-scrollbar 
  width: 6px

  &-track 
  
  &-thumb 
    /* Handle */
    // background: #888
    border-radius: 5px

    &:hover 
      /* Handle on hover */
      background: rgb(110, 110, 110)
    
