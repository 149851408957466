// inspired by https://codepen.io/andrewerrico/pen/ExVQvZo
// inspired by http://www.jayvanhutten.com/

.project-container {
  display: grid;

  margin: auto;
  font-family: Arial, sans-serif;
  color: $main-color;
  cursor: default;
  //   align-items: center;

  .game-image {
    max-width: 100%;
    width: 100%;
    box-shadow: 4px 4px 20px -6px rgba(0, 0, 0, 0.25);
    border-radius: 0.3rem;
    anchor-name: --anchor-el;
  }

  .text-section {
    flex: 1;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 10px;

    > .text {
      flex: 1;
      text-align: start;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    > * {
      margin: 0 !important;
    }
  }

  ::first-letter {
    text-transform: capitalize;
  }

  h2 {
    margin-top: 0;
    text-transform: uppercase;
  }

  p {
    font-size: unset;
    margin: 0;
  }

  .tag {
    display: inline-block;
    background-color: #d9534f;
    color: white;
    text-transform: capitalize;
    padding: 4px 8px;
    margin: 4px 0;
    margin-right: 10px;
    border-radius: 5px;
  }

  ul {
    list-style-type: none;
    padding-inline: 0;
    display: grid;
    gap: 5px;

    > p {
      margin: 5px 0;
    }

    > li {
      background-color: #00000024;
      padding: 0.3em;
      border-radius: 0.2em;

      &.expanded {
        background-color: #00000027;
      }

      &.expandable {
        cursor: pointer;

        &:not(.expanded) > svg {
          rotate: 180deg;
        }
      }

      > span::before {
        content: "•";
        padding-right: 0.5em;
      }

      > svg {
        > path {
          fill: $main-color;
        }
        width: auto;
        height: 1em;
        stroke: $main-color;
        float: right;
        margin: 1px;
      }
    }
  }

  .download {
    font-style: italic;
    font-size: 0.95em;
    gap: 1.25em;

    position: absolute;
    position-anchor: --anchor-el;
    bottom: anchor(96%);
    right: anchor(97%);
    z-index: 1000;

    & a {
      color: #337ab7;
    }

    svg {
      filter: drop-shadow(0px 0px 4px black);
    }
  }

  .sub-sections-menu {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.4em;
    font-weight: bold;
    gap: 0;

    svg {
      height: 1.2em;
      fill: $main-color;
    }

    .active {
      color: gray;
      fill: gray;
    }

    > * {
      padding: 0.3em 1em;
      &:not(:first-child) {
        border-left: 2px solid rgba(255, 255, 255, 0.1);
      }
      &:hover:not(.active) {
        cursor: pointer;
        color: $highlight-color;
        fill: $highlight-color;
      }
    }
  }

  > * {
    display: flex;
    flex-direction: column;
    > * {
      gap: 2vw;
    }
  }
}

@media only screen and (max-width: 1000px) {
  div:has(> swiper-container) {
    max-width: 96vw;
  }
  .project-container > * {
    > * {
      display: flex;
      flex-direction: column;
    }
    padding: 5px;
    padding-bottom: 4vh;
  }
}

@media only screen and (min-width: 1000px) {
  div:has(> swiper-container) {
    max-width: calc(-30px + 96vw);
  }
  .project-container > * {
    > * {
      display: grid;
      grid-template-columns: 2fr 1fr;
    }
    padding: 5vh 4vw;
  }
}
