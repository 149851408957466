.Chapter {
  text-align: start;
  max-width: 95%;
  margin: 0 auto;

  .Chapter-header {
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    border-bottom: 2px solid hsl(0, 0%, 100%, 0.15);
  }

  .Chapter-content {
    min-height: 100vh;
    align-content: center;
  }
  .Chapter-header-text {
    color: $secondary-color;
    font-size: 2.5rem;
    text-transform: capitalize;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    cursor: pointer;
    display: block;
    width: 100%;
  }
}

.Chapter-header-text::before {
  color: transparent;
  position: relative;
  transition: 200ms;
  left: -0.75em;
  position: relative;

  content: "#";
}

.Chapter-header-text:hover::before {
  transition: 200ms;
  color: $main-color;
  transform: translateX(20px);
  left: -0.1em;
}
