nav {
  background-color: $background-light-1;
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 1000;

  a {
    color: $secondary-color;
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none;
    padding: 2vh 1em;
    font-family: sans-serif;
  }
  a:hover {
    background-color: color-mix(in srgb, $background-light-1 85%, black);
  }
}
