*:has(> a svg) {
  display: flex;
  column-gap: 0.75em;
  margin-top: 0.4em;

  @media (prefers-reduced-motion: no-preference) {
    animation: Slide-in 1s backwards;
  }

  svg {
    height: 30px;
    fill: $secondary-color;
    float: left;
    transition: 0.3s;

    &:hover {
      transform: scale(1.05);
      fill: $main-color;
      transition: 0.1s;
    }
  }
}

.load-first {
  animation-play-state: paused !important;
}

//My avatar logo//
.avatar {
  // animation: wobble 6s infinite alternate ease-in-out,
  //   blur-in 6s both cubic-bezier(0.18, 0.1, 0.2, 1);
  image-rendering: -webkit-optimize-contrast;
  max-width: min(100%, 400px);
  user-select: none;
  -webkit-user-drag: none;
  border-radius: 100%;
}

@keyframes blur-in {
  0% {
    opacity: 20%;
    filter: blur(15px);
    transform: scale(88%);
  }
}

@keyframes spin-enter {
  from {
    transform: scale(0%) rotateZ(-360 * 1deg);
  }
}

@keyframes wobble {
  from {
    transform: rotateZ(3deg);
  }
  to {
    transform: rotateZ(-3deg);
  }
}

// scroll background image
@keyframes scroll {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 200% 200%;
  }
}

.Canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
  // filter: blur(3px);
  height: 100vh;
  width: 100vw;

  animation: Fade-in 20s ease-in;
}

@keyframes Fade-from-black {
  from {
    filter: brightness(0);
  }
  to {
  }
}

@keyframes Slide-in {
  from {
    column-gap: 0.2rem;
    // transform: translateX(1em);
  }
}

@keyframes Fade-in {
  from {
    opacity: 0%;
  }
}

@keyframes App-logo-jump {
  from {
    bottom: 0px;
  }
  to {
    bottom: 80px;
  }
}

@keyframes App-logo-spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(300deg);
  }
}
