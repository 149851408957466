@import url("https://fonts.googleapis.com/css2?family=Acme&family=Caveat&family=Fredoka&family=Fredoka+One&family=Indie+Flower&family=Roboto+Slab&family=Sacramento&family=Sansita+Swashed&family=Supermercado+One&display=swap");
/*
		cool header text
	font-family: 'Acme', sans-serif;
		clean handwriting
	font-family: 'Caveat', cursive;
		simplistic round text
	font-family: 'Fredoka', sans-serif;
		thich round header text
	font-family: 'Fredoka One', cursive;
		playful comic sans
	font-family: 'Indie Flower', cursive;
		clean paragraph text
	font-family: 'Roboto Slab', serif;
		Stylish handwriting for quoting
	font-family: 'Sacramento', cursive;
		stylish header text
	font-family: 'Sansita Swashed', cursive;
		round title text
	font-family: 'Supermercado One', cursive;
*/

$dark-background: #221d1a;
$background-light-1: color-mix(in srgb, $dark-background 93%, white);
$main-color: #ff8569;
$secondary-color: #f7f0da;
$highlight-color: color-mix(in srgb, $main-color 45%, white);
$white: #d3d3d3; // not in use (too gray)

p {
  color: $secondary-color;
  font-size: 2.5vh;
}

:root {
  font-family: "Roboto Slab";
  color: $secondary-color;
  --main-color: #ff8569;
}

body {
  background-color: $dark-background;
  margin: 0;
  overflow-x: hidden;
}

.default-width {
  max-width: calc(90vw - 30px);
  margin-right: auto;
  margin-left: auto;
}

.App > :not(nav, .Chapter) {
  min-height: 100vh;
  margin: auto;
}

.List-style {
  font-family: Fredoka;
  border-radius: 0.3em;
  background: rgb(255 255 255 / 10%);
  width: fit-content;
  padding: 0.5em;
  padding-left: 1.5em;

  > li {
    color: $main-color;
  }

  li::marker {
    color: rgba(255, 255, 255, 0.4);
  }

  ul li {
    color: rgba(187, 177, 150, 0.639);
  }
}

.Highlight {
  color: $main-color;
  font-size: 1.2em;
}

.header-container {
  display: grid;
  justify-items: center;
  gap: 5vw;
  width: 100%;

  @media screen and (min-width: 1100px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.header-intro-container {
  display: grid;
  gap: 1vw;
  width: fit-content;

  * {
    margin: 0;
    padding: 0;
  }

  font-size: 3.5vw;
  @media screen and (max-width: 1100px) {
    font-size: 6vw;
  }

  > .name-header {
    background: -webkit-linear-gradient(#ff947c, #ff7251);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: $main-color;
    width: fit-content;
    font-size: 1em;
    cursor: default;
    font-family: "Fredoka";
    @media (prefers-reduced-motion: no-preference) {
      animation: Fade-in 1.2s;
    }
  }

  > .below-header-name {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    #links {
      gap: 0.6em;

      a {
        margin-top: 4px;
      }

      svg {
        height: 0.75em;
      }
    }

    > .header-description {
      font-family: "Fredoka";
      font-size: 0.5em;
      color: $secondary-color;
      text-align: right;
      width: 16em;
      @media (prefers-reduced-motion: no-preference) {
        animation: Fade-in 1.2s;
      }
    }
  }
}

a {
  color: rgb(255 95 91);
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: white;
  }
}

.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: $white;

  height: 90vh;
  margin: 0 2em;
}

// Image gallery styles
@media screen and (max-width: 50rem) {
  swiper-container img {
    max-width: 100%;
  }
}
@media screen and (min-width: 50rem) {
  swiper-container img {
    max-width: 90%;
  }
}
