.GalleryText {
  font-family: "Fredoka";
  text-align: center;
  margin-top: 1rem;
}

.ImageGallery {
  $button-icon-overflow: 2rem;
  $buttonWidth: 45%;
  $button-icon-padding: 1rem;
  $button-offset: calc($button-icon-padding + $button-icon-overflow + 37.6px);

  user-select: none;
  -webkit-user-drag: none;

  width: 75%;
  margin: auto;
  height: 70vh;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    margin: 0% (-$buttonWidth);
    border-radius: 0.3vw;
  }

  .left-button {
    justify-content: start;
    position: relative;
    right: $button-offset;
    height: 100%;
    .button-icon {
      transform: translateX($button-icon-overflow);
    }
  }

  .right-button {
    justify-content: end;
    position: relative;
    left: $button-offset;
    height: 100%;
    .button-icon {
      transform: translateX(-$button-icon-overflow);
    }
  }

  .button {
    z-index: 1;
    background-color: hsla(0, 0%, 0%, 0);
    min-width: $buttonWidth;
    cursor: pointer;

    display: flex;
    align-items: center;

    &:hover .button-icon {
      transition: ease-in-out 200ms;
      color: $main-color;
      transform: translateX(0);
    }
  }

  .button-icon {
    color: $secondary-color;
    transition: ease-in-out 200ms;
    font-weight: bold;
    font-size: 2rem;
    padding: 0 $button-icon-padding;
  }
}
