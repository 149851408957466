@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Acme&family=Caveat&family=Fredoka&family=Fredoka+One&family=Indie+Flower&family=Roboto+Slab&family=Sacramento&family=Sansita+Swashed&family=Supermercado+One&display=swap");
/*
		cool header text
	font-family: 'Acme', sans-serif;
		clean handwriting
	font-family: 'Caveat', cursive;
		simplistic round text
	font-family: 'Fredoka', sans-serif;
		thich round header text
	font-family: 'Fredoka One', cursive;
		playful comic sans
	font-family: 'Indie Flower', cursive;
		clean paragraph text
	font-family: 'Roboto Slab', serif;
		Stylish handwriting for quoting
	font-family: 'Sacramento', cursive;
		stylish header text
	font-family: 'Sansita Swashed', cursive;
		round title text
	font-family: 'Supermercado One', cursive;
*/
p {
  color: #f7f0da;
  font-size: 2.5vh;
}

:root {
  font-family: "Roboto Slab";
  color: #f7f0da;
  --main-color: #ff8569;
}

body {
  background-color: #221d1a;
  margin: 0;
  overflow-x: hidden;
}

.default-width {
  max-width: calc(90vw - 30px);
  margin-right: auto;
  margin-left: auto;
}

.App > :not(nav, .Chapter) {
  min-height: 100vh;
  margin: auto;
}

.List-style {
  font-family: Fredoka;
  border-radius: 0.3em;
  background: rgba(255, 255, 255, 0.1);
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5em;
  padding-left: 1.5em;
}
.List-style > li {
  color: #ff8569;
}
.List-style li::marker {
  color: rgba(255, 255, 255, 0.4);
}
.List-style ul li {
  color: rgba(187, 177, 150, 0.639);
}

.Highlight {
  color: #ff8569;
  font-size: 1.2em;
}

.header-container {
  display: grid;
  justify-items: center;
  gap: 5vw;
  width: 100%;
}
@media screen and (min-width: 1100px) {
  .header-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.header-intro-container {
  display: grid;
  gap: 1vw;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 3.5vw;
}
.header-intro-container * {
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 1100px) {
  .header-intro-container {
    font-size: 6vw;
  }
}
.header-intro-container > .name-header {
  background: -webkit-linear-gradient(#ff947c, #ff7251);
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #ff8569;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1em;
  cursor: default;
  font-family: "Fredoka";
}
@media (prefers-reduced-motion: no-preference) {
  .header-intro-container > .name-header {
    animation: Fade-in 1.2s;
  }
}
.header-intro-container > .below-header-name {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.header-intro-container > .below-header-name #links {
  gap: 0.6em;
}
.header-intro-container > .below-header-name #links a {
  margin-top: 4px;
}
.header-intro-container > .below-header-name #links svg {
  height: 0.75em;
}
.header-intro-container > .below-header-name > .header-description {
  font-family: "Fredoka";
  font-size: 0.5em;
  color: #f7f0da;
  text-align: right;
  width: 16em;
}
@media (prefers-reduced-motion: no-preference) {
  .header-intro-container > .below-header-name > .header-description {
    animation: Fade-in 1.2s;
  }
}

a {
  color: rgb(255, 95, 91);
  cursor: pointer;
  text-decoration: underline;
}
a:hover {
  color: white;
}

.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: #d3d3d3;
  height: 90vh;
  margin: 0 2em;
}

@media screen and (max-width: 50rem) {
  swiper-container img {
    max-width: 100%;
  }
}
@media screen and (min-width: 50rem) {
  swiper-container img {
    max-width: 90%;
  }
}
*:has(> a svg) {
  display: flex;
  -moz-column-gap: 0.75em;
       column-gap: 0.75em;
  margin-top: 0.4em;
}
@media (prefers-reduced-motion: no-preference) {
  *:has(> a svg) {
    animation: Slide-in 1s backwards;
  }
}
*:has(> a svg) svg {
  height: 30px;
  fill: #f7f0da;
  float: left;
  transition: 0.3s;
}
*:has(> a svg) svg:hover {
  transform: scale(1.05);
  fill: #ff8569;
  transition: 0.1s;
}

.load-first {
  animation-play-state: paused !important;
}

.avatar {
  image-rendering: -webkit-optimize-contrast;
  max-width: min(100%, 400px);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  -webkit-user-drag: none;
  border-radius: 100%;
}

@keyframes blur-in {
  0% {
    opacity: 20%;
    filter: blur(15px);
    transform: scale(88%);
  }
}
@keyframes spin-enter {
  from {
    transform: scale(0%) rotateZ(-360deg);
  }
}
@keyframes wobble {
  from {
    transform: rotateZ(3deg);
  }
  to {
    transform: rotateZ(-3deg);
  }
}
@keyframes scroll {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 200% 200%;
  }
}
.Canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
  height: 100vh;
  width: 100vw;
  animation: Fade-in 20s ease-in;
}

@keyframes Fade-from-black {
  from {
    filter: brightness(0);
  }
}
@keyframes Slide-in {
  from {
    -moz-column-gap: 0.2rem;
         column-gap: 0.2rem;
  }
}
@keyframes Fade-in {
  from {
    opacity: 0%;
  }
}
@keyframes App-logo-jump {
  from {
    bottom: 0px;
  }
  to {
    bottom: 80px;
  }
}
@keyframes App-logo-spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(300deg);
  }
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  /* Handle */
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  /* Handle on hover */
  background: rgb(110, 110, 110);
}

.GalleryText {
  font-family: "Fredoka";
  text-align: center;
  margin-top: 1rem;
}

.ImageGallery {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  -webkit-user-drag: none;
  width: 75%;
  margin: auto;
  height: 70vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ImageGallery img {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  margin: 0% -45%;
  border-radius: 0.3vw;
}
.ImageGallery .left-button {
  justify-content: start;
  position: relative;
  right: calc(3rem + 37.6px);
  height: 100%;
}
.ImageGallery .left-button .button-icon {
  transform: translateX(2rem);
}
.ImageGallery .right-button {
  justify-content: end;
  position: relative;
  left: calc(3rem + 37.6px);
  height: 100%;
}
.ImageGallery .right-button .button-icon {
  transform: translateX(-2rem);
}
.ImageGallery .button {
  z-index: 1;
  background-color: hsla(0, 0%, 0%, 0);
  min-width: 45%;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.ImageGallery .button:hover .button-icon {
  transition: ease-in-out 200ms;
  color: #ff8569;
  transform: translateX(0);
}
.ImageGallery .button-icon {
  color: #f7f0da;
  transition: ease-in-out 200ms;
  font-weight: bold;
  font-size: 2rem;
  padding: 0 1rem;
}

.Chapter {
  text-align: start;
  max-width: 95%;
  margin: 0 auto;
}
.Chapter .Chapter-header {
  padding-bottom: 2rem;
  margin-bottom: 3rem;
  border-bottom: 2px solid hsla(0, 0%, 100%, 0.15);
}
.Chapter .Chapter-content {
  min-height: 100vh;
  align-content: center;
}
.Chapter .Chapter-header-text {
  color: #f7f0da;
  font-size: 2.5rem;
  text-transform: capitalize;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  cursor: pointer;
  display: block;
  width: 100%;
}

.Chapter-header-text::before {
  color: transparent;
  position: relative;
  transition: 200ms;
  left: -0.75em;
  position: relative;
  content: "#";
}

.Chapter-header-text:hover::before {
  transition: 200ms;
  color: #ff8569;
  transform: translateX(20px);
  left: -0.1em;
}

.LineSegment {
  border-top: 0.1em dashed hsla(0, 0%, 50%, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em auto 0 auto;
  width: 90%;
}
.LineSegment p {
  color: hsla(0, 0%, 50%, 0.5);
  font-style: italic;
  font-weight: bold;
  font-size: 0.8em;
  position: relative;
  bottom: 0.6em;
  padding: 0 0.5em;
  background-color: black;
}

nav {
  background-color: color-mix(in srgb, #221d1a 93%, white);
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 1000;
}
nav a {
  color: #f7f0da;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  padding: 2vh 1em;
  font-family: sans-serif;
}
nav a:hover {
  background-color: color-mix(in srgb, color-mix(in srgb, #221d1a 93%, white) 85%, black);
}

.ScrollText {
  overflow-y: scroll;
  overflow-x: visible;
  height: -webkit-fill-available;
  margin: 2em 0;
}

.Side {
  height: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* width */
.ScrollText::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.ScrollText::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Handle */
.ScrollText::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 50%, 0.25);
  border-radius: 1em;
}

/* Handle on hover */
.ScrollText::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ExperienceSection {
  background-color: rgba(255, 131, 92, 0.1882352941);
  display: grid;
  justify-content: center;
  width: -moz-max-content;
  width: max-content;
  border-radius: 0.3em;
}
.ExperienceSection :has(> .TagGroup) {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ExperienceSection .TagGroup {
  display: flex;
  flex-direction: row;
  padding: 0.6em;
}
.ExperienceSection .TagGroup .Tag {
  font-family: monospace;
  font-size: 1rem;
  border-radius: 0.3em;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 2em;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.3em 0.5em;
  margin-right: 1em;
  box-shadow: rgba(0, 0, 0, 0.2392156863) 3px 2px 2px 1px;
  cursor: default;
}

.project-container {
  display: grid;
  margin: auto;
  font-family: Arial, sans-serif;
  color: #ff8569;
  cursor: default;
}
.project-container .game-image {
  max-width: 100%;
  width: 100%;
  box-shadow: 4px 4px 20px -6px rgba(0, 0, 0, 0.25);
  border-radius: 0.3rem;
  anchor-name: --anchor-el;
}
.project-container .text-section {
  flex: 1;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.project-container .text-section > .text {
  flex: 1;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.project-container .text-section > * {
  margin: 0 !important;
}
.project-container ::first-letter {
  text-transform: capitalize;
}
.project-container h2 {
  margin-top: 0;
  text-transform: uppercase;
}
.project-container p {
  font-size: unset;
  margin: 0;
}
.project-container .tag {
  display: inline-block;
  background-color: #d9534f;
  color: white;
  text-transform: capitalize;
  padding: 4px 8px;
  margin: 4px 0;
  margin-right: 10px;
  border-radius: 5px;
}
.project-container ul {
  list-style-type: none;
  padding-inline: 0;
  display: grid;
  gap: 5px;
}
.project-container ul > p {
  margin: 5px 0;
}
.project-container ul > li {
  background-color: rgba(0, 0, 0, 0.1411764706);
  padding: 0.3em;
  border-radius: 0.2em;
}
.project-container ul > li.expanded {
  background-color: rgba(0, 0, 0, 0.1529411765);
}
.project-container ul > li.expandable {
  cursor: pointer;
}
.project-container ul > li.expandable:not(.expanded) > svg {
  rotate: 180deg;
}
.project-container ul > li > span::before {
  content: "•";
  padding-right: 0.5em;
}
.project-container ul > li > svg {
  width: auto;
  height: 1em;
  stroke: #ff8569;
  float: right;
  margin: 1px;
}
.project-container ul > li > svg > path {
  fill: #ff8569;
}
.project-container .download {
  font-style: italic;
  font-size: 0.95em;
  gap: 1.25em;
  position: absolute;
  position-anchor: --anchor-el;
  bottom: anchor(96%);
  right: anchor(97%);
  z-index: 1000;
}
.project-container .download a {
  color: #337ab7;
}
.project-container .download svg {
  filter: drop-shadow(0px 0px 4px black);
}
.project-container .sub-sections-menu {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.4em;
  font-weight: bold;
  gap: 0;
}
.project-container .sub-sections-menu svg {
  height: 1.2em;
  fill: #ff8569;
}
.project-container .sub-sections-menu .active {
  color: gray;
  fill: gray;
}
.project-container .sub-sections-menu > * {
  padding: 0.3em 1em;
}
.project-container .sub-sections-menu > *:not(:first-child) {
  border-left: 2px solid rgba(255, 255, 255, 0.1);
}
.project-container .sub-sections-menu > *:hover:not(.active) {
  cursor: pointer;
  color: color-mix(in srgb, #ff8569 45%, white);
  fill: color-mix(in srgb, #ff8569 45%, white);
}
.project-container > * {
  display: flex;
  flex-direction: column;
}
.project-container > * > * {
  gap: 2vw;
}

@media only screen and (max-width: 1000px) {
  div:has(> swiper-container) {
    max-width: 96vw;
  }
  .project-container > * {
    padding: 5px;
    padding-bottom: 4vh;
  }
  .project-container > * > * {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (min-width: 1000px) {
  div:has(> swiper-container) {
    max-width: calc(-30px + 96vw);
  }
  .project-container > * {
    padding: 5vh 4vw;
  }
  .project-container > * > * {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
}/*# sourceMappingURL=index.css.map */